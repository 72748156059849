export const ROLES = {
  ADMIN: 'ADMIN',
  GENERAL_USER: 'GENERAL_USER',
  PROJECT_MANAGER: 'PROJECT_MANAGER',
  RESOURCE_MANAGER: 'RESOURCE_MANAGER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  PRODUCT_ADMIN: 'PRODUCT_ADMIN',
  EXECUTIVE: 'EXECUTIVE'
};
export const USER = 'user';
