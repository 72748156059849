import { PRIVILIGES_CONSTANTS } from 'constants/PrivilegesConstant';
import ROUTES from 'constants/RouteConstants';

export const publicRoutes = [
  ROUTES.LOGIN,
  ROUTES.REGISTER,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.SET_PASSWORD,
  ROUTES.RESET_PASSWORD,
  ROUTES.PAGE_NOT_FOUND,
  ROUTES.JIRA_CALLBACK
];

export const webRoutes = [
  ROUTES.PRICING_PACKAGES
];

const {
  CADENCE, PROJECT_METRICS, BUDGET, RESOURCE_TEAM, USER, USER_REQUESTS, BILLING_CYCLE,
  PROJECT_MANAGER_DASHBOARD, JIRA_TIMELINE, RISK_MANAGEMENT,
  PROJECT_RESOURCES_DASHBOARD, PROJECT_BUDGET, PROJECT_OKRS,
  PROJECT_HISTORY, RESOURCE_MANAGER_DASHBOARD, RESOURCE_COMMITMENT,
  RESOURCE_MANAGER_MY_RISKS, BILLING_DASHBOARD,
  GENERAL_USER_MY_PROJECTS, GENERAL_USER_MY_RISKS,
  GENERAL_USER_MY_WORK,
  EXECUTIVE_DASHBOARD,
  RESOURCE_REQUESTS,
  // PAYMENT_OPTIONS_DASHBOARD,
  PRODUCT_ADMIN_DASHBOARD, CUSTOMER_ACTIVATION,
  PENDING_INVOICE, ADMIN_DASHBOARD, FAQ
} = PRIVILIGES_CONSTANTS;

export const privateRoutes = {
  SUPER_ADMIN: {
    [ADMIN_DASHBOARD.key]: ADMIN_DASHBOARD,
    [CADENCE.key]: CADENCE,
    [PROJECT_METRICS.key]: PROJECT_METRICS,
    [BUDGET.key]: BUDGET,
    [RESOURCE_TEAM.key]: RESOURCE_TEAM,
    [USER.key]: USER,
    [USER_REQUESTS.key]: USER_REQUESTS,
    [BILLING_CYCLE.key]: BILLING_CYCLE,
    // [PAYMENT_OPTIONS_DASHBOARD.key]: PAYMENT_OPTIONS_DASHBOARD,
    [PENDING_INVOICE.key]: PENDING_INVOICE,
    [PROJECT_MANAGER_DASHBOARD.key]: PROJECT_MANAGER_DASHBOARD,
    [JIRA_TIMELINE.key]: JIRA_TIMELINE,
    [RISK_MANAGEMENT.key]: RISK_MANAGEMENT,
    [PROJECT_RESOURCES_DASHBOARD.key]: PROJECT_RESOURCES_DASHBOARD,
    [PROJECT_BUDGET.key]: PROJECT_BUDGET,
    [PROJECT_OKRS.key]: PROJECT_OKRS,
    [PROJECT_HISTORY.key]: PROJECT_HISTORY,
    [RESOURCE_MANAGER_DASHBOARD.key]: RESOURCE_MANAGER_DASHBOARD,
    [RESOURCE_COMMITMENT.key]: RESOURCE_COMMITMENT,
    [RESOURCE_MANAGER_MY_RISKS.key]: RESOURCE_MANAGER_MY_RISKS,
    [GENERAL_USER_MY_PROJECTS.key]: GENERAL_USER_MY_PROJECTS,
    [GENERAL_USER_MY_RISKS.key]: GENERAL_USER_MY_RISKS,
    [GENERAL_USER_MY_WORK.key]: GENERAL_USER_MY_WORK,
    [BILLING_DASHBOARD.key]: BILLING_DASHBOARD,
    [RESOURCE_REQUESTS.key]: RESOURCE_REQUESTS,
    [PRODUCT_ADMIN_DASHBOARD.key]: PRODUCT_ADMIN_DASHBOARD,
    [CUSTOMER_ACTIVATION.key]: CUSTOMER_ACTIVATION,
    [FAQ.key]: FAQ,
    [EXECUTIVE_DASHBOARD.key]: { ...EXECUTIVE_DASHBOARD }
  },

  ADMIN: {
    [ADMIN_DASHBOARD.key]: ADMIN_DASHBOARD,
    [EXECUTIVE_DASHBOARD.key]: { ...EXECUTIVE_DASHBOARD },
    [CADENCE.key]: CADENCE,
    [PROJECT_METRICS.key]: PROJECT_METRICS,
    [BUDGET.key]: BUDGET,
    [RESOURCE_TEAM.key]: RESOURCE_TEAM,
    [USER.key]: USER,
    [USER_REQUESTS.key]: USER_REQUESTS,
    [BILLING_CYCLE.key]: BILLING_CYCLE,
    // [PAYMENT_OPTIONS_DASHBOARD.key]: PAYMENT_OPTIONS_DASHBOARD,
    [PENDING_INVOICE.key]: PENDING_INVOICE,
    [FAQ.key]: FAQ
  },

  RESOURCE_MANAGER: {
    [RESOURCE_MANAGER_DASHBOARD.key]: { ...RESOURCE_MANAGER_DASHBOARD, order: 1 },
    [RESOURCE_COMMITMENT.key]: { ...RESOURCE_COMMITMENT, order: 2 },
    [RESOURCE_REQUESTS.key]: { ...RESOURCE_REQUESTS, order: 3 },
    [RESOURCE_MANAGER_MY_RISKS.key]: { ...RESOURCE_MANAGER_MY_RISKS, order: 4 },
    [FAQ.key]: FAQ
  },

  PROJECT_MANAGER: {
    [PROJECT_MANAGER_DASHBOARD.key]: { ...PROJECT_MANAGER_DASHBOARD, order: 1 },
    [JIRA_TIMELINE.key]: { ...JIRA_TIMELINE, order: 2 },
    [RISK_MANAGEMENT.key]: { ...RISK_MANAGEMENT, order: 3 },
    [PROJECT_RESOURCES_DASHBOARD.key]: { ...PROJECT_RESOURCES_DASHBOARD, order: 4 },
    [PROJECT_BUDGET.key]: { ...PROJECT_BUDGET, order: 5 },
    [PROJECT_OKRS.key]: { ...PROJECT_OKRS, order: 6 },
    [PROJECT_HISTORY.key]: { ...PROJECT_HISTORY, order: 7 },
    [FAQ.key]: FAQ
  },

  GENERAL_USER: {
    [GENERAL_USER_MY_PROJECTS.key]: { ...GENERAL_USER_MY_PROJECTS, order: 1 },
    [GENERAL_USER_MY_RISKS.key]: { ...GENERAL_USER_MY_RISKS, order: 2 },
    [GENERAL_USER_MY_WORK.key]: { ...GENERAL_USER_MY_WORK, order: 3 },
    [FAQ.key]: FAQ
  },
  EXECUTIVE: {
    [EXECUTIVE_DASHBOARD.key]: { ...EXECUTIVE_DASHBOARD, order: 1 },
    [FAQ.key]: FAQ
  }
};
