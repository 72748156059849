export const STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
};

export const RESOURCE_REQUEST_STATUS = {
  DRAFT: 'DRAFT',
  INPROGRESS: 'IN_PROGRESS'
};

export const INVOICE_STATUS = {
  NOT_PAID: 'NOT_PAID',
  OVERDUE: 'OVERDUE'
};
